<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mpais-modals :modelName="modelName"></mpais-modals>
	</div>
</template>

<script>
import mpaisActions from './MpaisActions';
import mpaisModals from './MpaisModals.vue';

export default {
	name: 'mpais-grid',
	components: {
		'mpais-modals': mpaisModals
	},
	data() {
		return {
			modelName: 'mpais',
			actions: mpaisActions.gridactions,
			modelColumnDefs: {
				escomunitario: {
					render: data => {
						if (data === 'S') {
							return this.returnYes;
						} else {
							return this.returnNo;
						}
					}
				}
			}
		};
	},
	computed: {
		returnYes() {
			return this.$t('yes');
		},

		returnNo() {
			return this.$t('no');
		}
	}
};
</script>
